import React from 'react';
import { Link } from 'react-router-dom';
const footerWrap = () => {

    return (

        <footer className="footer">
            <div className="footerline"></div>
            <div className="container">
                <div className="row">



                    <div className="col-xs-12 col-sm-3 ">
                        <div className="footer-widget">
                            <h3>  </h3>
                            <div className="footer-widget-content">
                                <div className="footer-widget-content">
                                    <img src="/img/logo_ru.png"/>
                                    <p>ООО «Таткнигоиздат»</p>
                                    <p><a href="https://tatkniga.ru/upload/xlsx/tatkniga-ru-price.xlsx" download>ПРАЙС ЛИСТ</a></p>
                                    <ul className="social ">


                                        <li><a href="https://vk.com/tatkniga"><i className="fab fa-vk"></i></a></li>
                                        <li><a href="https://t.me/tatkniga"><i className="fab fa-telegram"></i></a></li>
                                        <li><a href="https://www.youtube.com/channel/UC-LiXppCaDgdsMFC9-OVQ7A"><i className="fab fa-youtube"></i></a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-xs-6 col-sm-3 ">
                        <div className="footer-widget">
                            <h3>Контакты</h3>
                            <div className="footer-widget-content">

                             
                                <p className="red text-uppercase">Приёмная:</p>                          
                                <a href="tel:0121234" className="contact-link">  (843) 519-45-22</a>
                                <a href="mailto:sales@example.com" className="contact-link"> tki@tatkniga.ru</a>
                                <p className="red text-uppercase"> Маркетинг:</p>
                                <a href="mailto: tki_marketing@mail.ru" className="contact-link">  tki_marketing@mail.ru </a>
                                <a href="tel:0121234" className="contact-link"> 519-45-35, 519-45-12</a>
                          
                            </div>
                        </div>
                    </div>
             
                    <div className="col-xs-6 col-sm-3 ">
                        <div className="footer-widget">
                            <h3>О компании</h3>
                            <div className="footer-widget-content">
                                <div className="footer-widget-content">


                                    
                                    <p><Link to="/about" className="contact-link  red text-uppercase">  О компании</Link></p>

                                        <p><Link to="/about-details" className="contact-link  text-uppercase"> Реквизиты</Link></p>

                                            <p><Link to="/support" className=" text-uppercase">Служба поддержки</Link></p>

                                    <p><Link to="/public-offers" className="contact-link   text-uppercase">Обработка и защита персональных данных</Link></p>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-3 ">
                        <div className="footer-widget">
                            <Link to="our-stores"><h3> Фирменные магазины</h3><span><i className="bi bi-geo-alt"></i> на карте</span></Link>
                            <div className="footer-widget-content">
                                <div className="footer-widget-content">


                                    <p className="red text-uppercase">г. Казань, ул. Декабристов, 2 ("Литературное кафе в здании ТАТМЕДИА")  - 1 ЭТАЖ</p>
                                    <a href="tel:+79276710500" className="contact-link">  +7 (927) 671-05-00 </a>
                                    
                                    <p className="red text-time">
                                        Будние дни 08:00 — 18:00</p>

                                    <p className="red text-uppercase"> г. Казань, ул. Баумана, 19</p>
                                    <p className="red text-time">Понедельник — Воскресенье 10:00 — 20:00</p>
                                    <a href="tel:+78432947050" className="contact-link"> +7 (843) 294-70-50</a>

                                   


                                </div>
                            </div>
                        </div>
                    </div>

        

          
    </div>
  </div>
</footer>

    );

}

export default footerWrap;